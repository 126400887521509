import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { WineClubInfo } from "../../components/Forms/wineclub-info";
import { MemberInfo } from "../../components/Forms/member-info";
import { SecondaryMemberInfo } from "../../components/Forms/secondary-member-info";
import { ContactInfo } from "../../components/Forms/contact-info";
import { HomeAddressInfo } from "../../components/Forms/home-address-info";
import { ShippingAddressInfo } from "../../components/Forms/shipping-address-info";
import { memberActions } from "../../_actions";
import { memberService, paymentService } from "../../_services";
import get from "lodash/get";
import "./styles.scoped.css";
import secureStorage from "../../_helpers/secureStorage";
import { logGTMEvent} from "../../_helpers";

const Profile = ({
  profileObject,
  status,
  dispatch,
  isLoading,
  history,
  membershipType,
}) => {
  const [validated, setValidated] = useState(false);
  const [price, setPrice] = useState(
    profileObject.contact.CHW_Cost_of_Membership__c
  );
  const [bottleLevel, setBottleLevel] = useState(null);
  const [clubType, setClubType] = useState(null);
  const [initialPriceSet, setInitialPriceSet] = useState(false);
  const [initialPrice, setInitialPrice] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [secondaryHasContent, setSecondaryHasContent] = useState(false)
  /*eslint-disable */
  useEffect(() => {
    if (!initialPriceSet) {
      setInitialPrice(price);
      setInterval(() => {
        setInitialPriceSet(true);
      }, 2000);
    }
  }, [price]);
  /*eslint-enable */

  useEffect(() => {
    if (bottleLevel === null && profileObject.displayLevel) {
      setBottleLevel(profileObject.displayLevel);
    }
    if (clubType === null && profileObject.displayClubType) {
      setClubType(profileObject.displayClubType);
    }
  }, [profileObject.displayDelivery, profileObject.displayClubType])

  const handleSubmit = event => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (initialPrice !== price) {
        if (
          window.confirm(
            `Your membership cost will change from $${initialPrice} to $${price}. Are you sure you want to make this change?`
          )
        ) {
          setInitialPrice(price);
        }
      }
      if (status === "Inactive" || status === "Cancel") {
        memberService.postProfile(profileObject).then(() => {
          paymentService
            .getPaymentForm(`${price}`, "REJOIN", "1", "SALE")
            .then(res => {
              history.push({
                pathname: "/UpdateCard",
                state: { url: res.with }
              });
            });
        });
      } else {
        dispatch(memberActions.postProfile(profileObject));
        setProfileUpdated(true);
        if (profileObject.displayClubType !== clubType || profileObject.displayLevel !== bottleLevel) {
          logGTMEvent('update_member_type', {page_title: 'Profile', page_path: '/main#profile', page_referrer: `${document.referrer}`}, {number_of_bottles: profileObject.displayLevel, item_variant: profileObject.displayClubType})
        }
        logGTMEvent('update_profile', {page_title: 'Profile', page_path: '/main#profile', page_referrer: `${document.referrer}`})
      }
    }
  };

  return (
    <Form noValidate validated={validated}>
      <Row>
        <Col lg={6}>
          <WineClubInfo
            editablePrice={price}
            status={status}
            signup="profile"
            costChange={p => setPrice(p)}
          />
          <MemberInfo signup="profile" />
          <SecondaryMemberInfo 
          secondaryDataChange={d => setSecondaryHasContent(d)}
          required={secondaryHasContent} />
        </Col>
        <Col lg={6}>
          <ContactInfo signup="profile" />
          <HomeAddressInfo required={true} signup="profile" />
          <ShippingAddressInfo required={false} signup="profile" />
        </Col>
        <Col md={12} className="text-center">
          {!isLoading && profileUpdated && (
            <div
              class="col-12 my-1 "
              id="update_results"
              style={{ color: "white" }}
            >
              Updated Successfully
            </div>
          )}

          {(membershipType === "Gift" &&
            status !== "To be Activated" &&
            status !== "Active") ||
          status === "UTP" ? null : !isLoading ? (
            <Button style={{ margin: "3em 0" }} onClick={e => handleSubmit(e)}>
              {status === "Inactive" || status === "Cancel"
                ? "Rejoin Club"
                : "Update Profile"}
            </Button>
          ) : (
            <Button style={{ margin: "3em 0" }}>Updating...</Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

function mapStateToProps(state) {
  const { member } = state;
  const shippingAddress = get(member, "data.shippingAddress", {});
  const homeAddress = get(member, "data.homeAddress", {});
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contactId = get(user, "id", "");

  const firstName = get(member, "data.firstName", "");
  const lastName = get(member, "data.lastName", "");
  const birthDate = get(member, "data.birthday", "");
  const membershipType = get(member, "data.membershipType", "");
  const clubType = get(member, "data.clubType", "");
  const bottleLevel = get(member, "data.bottleLevel", "");
  const deliveryType = get(member, "data.deliveryType", "");
  const phone = get(member, "data.phone", "");
  const email = get(member, "data.email", "");
  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zip = get(homeAddress, "zipCode", "");
  const shippingStreet = get(shippingAddress, "street", "");
  const shippingCity = get(shippingAddress, "city", "");
  const shippingSt = get(shippingAddress, "state", "");
  const shippingZip = get(shippingAddress, "zipCode", "");
  const status = get(member, "data.status", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const secondaryMemberFn = get(member, "data.secondaryMemberFirstName", null);
  const secondaryMemberLn = get(member, "data.secondaryMemberLastName", null);
  const secondaryMemberBday = get(member, "data.secondaryMemberBirthday", null);

  const profileObject = {
    contact: {
      Id: contactId,
      FirstName: firstName,
      LastName: lastName,
      Birthdate: birthDate,
      CHW_Membership_Type__c: membershipType,
      Phone: phone,
      Email: email,
      MailingStreet: street,
      MailingCity: city,
      MailingState: st,
      MailingPostalCode: zip,
      OtherStreet: shippingStreet,
      OtherCity: shippingCity,
      OtherState: shippingSt,
      OtherPostalCode: shippingZip,
      CHW_Card_On_File_Optout__c: memberSignatureOptOut,
      CHW_Secondary_Contact_First_Name__c: secondaryMemberFn ? secondaryMemberFn : null,
      CHW_Secondary_Contact_Last_Name__c: secondaryMemberLn ? secondaryMemberLn : null,
      CHW_Secondary_Contact_Birthday__c: secondaryMemberBday ? secondaryMemberBday : null
    },
    displayClubType: clubType,
    displayLevel: bottleLevel,
    displayDelivery: deliveryType
  };

  const isLoading = member.loading;
  return { status, profileObject, isLoading, membershipType, member: member.data };
}

Profile.defaultProps = {
  signup: "profile"
};

const connectedProfile = withRouter(connect(mapStateToProps)(Profile));
export { connectedProfile as Profile };
