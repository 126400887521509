import React from "react";
import { connect } from "react-redux";
import { memberActions } from "../../_actions";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Gauge from "../../components/Gauge";
import LoadingSpinners from "../../components/LoadingSpinners";
import size from "lodash/size";
import get from "lodash/get";
import secureStorage from "../../_helpers/secureStorage";
import "./styles.css";
import { WhatsNew } from "./whats-new";
import { ReactComponent as BottleImage } from "../../assets/images/winebottle.svg";
import { ReactComponent as RewardImage } from "../../assets/images/gift.svg";
import { ReactComponent as MoneyImage } from "../../assets/images/rewards.svg";
import { ReactComponent as ProfileImage } from "../../assets/images/profile.svg";
import { ReactComponent as CCardImage } from "../../assets/images/creditcard.svg";
import { ReactComponent as LuxUpgradeImage } from "../../assets/images/lux.svg";
import BrSeal from "../../assets/images/br-seal.png";

const Dashboard = ({
  changeTab,
  member,
  rewards,
  isLoading,
  contacts,
  dispatch,
  selectorValue,
  barrelPromotionState,
  barrelReservePickupDate,
  barrelOrders,
  barrelReserveOrder,
}) => {
  const fName = get(member, "firstName", "");
  const currentPoints = get(member, "activePoints", 0);
  const ambassadorScore = get(member, "ambassadorScore", 0);
  const pickupBottles = get(member, "pickupBottles", 0);
  const promoBottles = get(member, "promoBottles", 0);
  const luxUpgrades = get(member, "openLuxUpgrades", 0);
  const bottleLevel = get(member, "bottleLevel", 1);
  const memberNumber = get(member, "memberNumber", 0);
  const memberSinceDate = get(member, "memberSinceDate", "");
  const membershipType = get(member, "membershipType", "");
  const tier = get(member, "tier", "");
  const showTier = tier && tier.includes("Ambassador");
  const pendingPromoBottle = get(member, "pendingPromoBottle", false);
  const pendingPromoReward = get(member, "pendingPromoReward", false);

  const memberStatus = get(member, "status", "");
  const expMonth = get(
    member,
    "storedPaymentAccount.BluePay__Expiration_Month__c",
    ""
  );
  const expYear = get(
    member,
    "storedPaymentAccount.BluePay__Expiration_Year__c",
    ""
  );
  const lastFour = get(
    member,
    "storedPaymentAccount.BluePay__Card_Number__c",
    ""
  ).slice(12, 16);
  const cardType = get(
    member,
    "storedPaymentAccount.BluePay__Card_Type__c",
    "Card"
  );
  const currentRewards = get(rewards, "activeMemberRewards", []);
  const totalCurrentRewards = size(currentRewards, "No");
  const availablePointsTotal =
    Math.round(currentPoints) + (350 - (Math.round(currentPoints) % 350));

  let numberOfBarrelOrders = 0;
  if (barrelOrders) {
    const x = barrelOrders?.map((order) => {
      numberOfBarrelOrders += order.bottleCount;
    });
  }

  if (barrelReserveOrder) {
    numberOfBarrelOrders += barrelReserveOrder?.magnumBottles + barrelReserveOrder?.regularBottles;
  }

  return isLoading || !member ? (
    <LoadingSpinners />
  ) : (
    <div>
      <Row>
        <Col md={5}>
          <Row>
            <Col md={12} sm={6} xs={6}>
              <Gauge
                points={currentPoints}
                availablePoints={availablePointsTotal}
                label="current points"
              />
            </Col>
            <Col md={12} sm={6} xs={6}>
              <Gauge
                points={ambassadorScore}
                availablePoints={7000}
                label="ambassador score"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="right">
            <Col xs={12}>
              {contacts && (
                <Row className="float-right member-title" align="right">
                  <Col md={12} className="pr-1">
                    <Row className="float-right" style={{ marginRight: 0 }}>
                      <Form.Label for="memberSelector">Membership Number: &nbsp;</Form.Label>

                      {contacts.length > 1 ? (
                        <Form.Control
                          value={selectorValue}
                          as="select"
                          id="memberSelector"
                          className="member-selector"
                          onChange={(e) => {
                            const contact = contacts.find(
                              (contact) =>
                                get(contact, "Id", "") === e.target.value
                            );
                            dispatch(memberActions.switchMember(contact));
                          }}
                        >
                          {contacts.map((contact) => (
                            <option
                              key={contact.CHW_Member_Number__c}
                              value={contact.Id}
                            >
                              {contact.CHW_Member_Number__c}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <Form.Label>
                          <strong>{memberNumber}</strong>
                        </Form.Label>
                      )}
                    </Row>
                  </Col>
                  <Col md={12} className="pr-1">
                    <Form.Label>
                      Member Since: <strong>{memberSinceDate}</strong>
                    </Form.Label>
                  </Col>
                  {showTier && (
                    <Col md={12} className="pr-1">
                      <Form.Label>
                        Tier: <strong>{tier}</strong>
                      </Form.Label>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
          <h2 className="my-3 mx-2" style={{fontSize: '1.5rem'}}>Welcome, {fName}</h2>
          <Row>
            <>
              <Col xs={2} className="my-auto pb-2">
                <BottleImage />
              </Col>
              <Col xs={10} className="my-auto pb-2">
                <span className="list-item-text">
                {pickupBottles} {pickupBottles === 1 ? "Bottle" : "Bottles"}{" "}
                  <a href="#bottles" onClick={() => changeTab(2)}>
                  available
                  </a>
                  .
                </span>
                <Row>
                {promoBottles > 0 &&
                <span className="sub-list-item-text">
                  +{promoBottles} Dine-in only bottle{promoBottles === 1 ? "" : "s"}{" "}
                  </span>
                  }
                </Row>

              </Col>
            </>
            {pendingPromoBottle && (
            <>
              <Col xs={2} className="my-auto pb-2">
              </Col>
              <Col xs={10} className="my-auto pb-2">
                <span className="list-item-text">
                  Welcome Bottle Benefit coming soon!
                </span>
              </Col>
            </>
            )}

            <Col xs={2} className="my-auto pb-2">
              <RewardImage />
            </Col>
            <Col xs={10} className="my-auto pb-2">
              <span className="list-item-text">
                {availablePointsTotal - currentPoints} points until the next{" "}
                <a href="#reward" onClick={() => changeTab(1)}>
                  reward
                </a>
                .
              </span>
            </Col>

            <Col xs={2} className="my-auto pb-2">
              <MoneyImage />
            </Col>

            <Col xs={10} className="my-auto pb-2">
              <span className="list-item-text">
                {totalCurrentRewards === 0 ? (
                  <> No rewards </>
                ) : totalCurrentRewards === 1 ? (
                  <> 1 reward </>
                ) : (
                  <> {totalCurrentRewards} rewards </>
                )}
                <a href="#reward" onClick={() => changeTab(1)}>
                  available
                </a>
                .
              </span>
            </Col>
            {(luxUpgrades > 0 || bottleLevel >= 3) && (
            <>
            <Col xs={2} className="my-auto pb-2">
              <LuxUpgradeImage />
            </Col>
            <Col xs={10} className="my-auto pb-2">
              <span className="list-item-text">
                {`${luxUpgrades} Lux bottle upgrade${luxUpgrades === 1 ? "" : "s"}`}{" "}
                <a href="#bottles" onClick={() => changeTab(2)}>
                  available
                </a>
                .
              </span>
            </Col>
            </>
            )}

            {pendingPromoReward && (
            <>
            <Col xs={2} className="my-auto pb-2">
            </Col>
            <Col xs={10} className="my-auto pb-2">
              <span className="coming-soon sub-list-item-text">
                Welcome Wine Flight coming soon!
              </span>
            </Col>
            </>
            )}

            <Col xs={2} className="my-auto pb-2">
              <ProfileImage />
            </Col>
            <Col xs={10} className="my-auto pb-2">
              <span className="list-item-text">
                <a href="#profile" onClick={() => changeTab(4)}>
                  Update Profile
                </a>
                .
              </span>
            </Col>

            {membershipType === "Gift" ||
            membershipType === "Founder" ? null : memberStatus === "Active" ||
              memberStatus === "New" ? (
              <>
                <Col xs={2} className="my-auto pb-2">
                  <CCardImage />
                </Col>
                <Col xs={10} className="my-auto pb-2">
                  <span className="list-item-text">
                    {cardType} ending in {lastFour} expires on {expMonth}/
                    {expYear}.
                    <a href="#payment" onClick={() => changeTab(4)}>
                      {" "}
                      Update
                    </a>
                  </span>
                </Col>
              </>
            ) : memberStatus === "Inactive" || memberStatus === "Cancel" ? (
              <>
                <Col xs={2} className="my-auto pb-2">
                  <CCardImage />
                </Col>
                <Col xs={10} className="my-auto pb-2">
                  <span className="list-item-text">
                    Your membership is no longer current.{" "}
                    <a href="#membership" onClick={() => changeTab(4)}>
                      Please Rejoin
                    </a>
                    .
                  </span>
                </Col>
              </>
            ) : memberStatus === "UTP" ? (
              <>
                <Col xs={2} className="my-auto pb-2">
                  <CCardImage />
                </Col>
                <Col xs={10} className="my-auto pb-2">
                  <span className="list-item-text">
                    Please{" "}
                    <a href="#payment" onClick={() => changeTab(4)}>
                      update your payment
                    </a>{" "}
                    selection.
                  </span>
                </Col>
              </>
            ) : null}

            {(barrelPromotionState === "PRESALE" ||
              barrelPromotionState === "SALE") &&
              (["Active","New","Inactive","Cancel","UTP","Vacation Hold"].includes(memberStatus)) && (
                <>
                  <Col xs={2} className="my-auto pb-2">
                    <img src={BrSeal} alt="br-seal" id="br-seal" alt="barrel reserve seal" />
                  </Col>
                  <Col xs={10} className="my-auto pb-2">
                    <span className="list-item-text">
                      <Link to="/BarrelReserve">Barrel Reserve</Link>
                    </span>
                    {!!numberOfBarrelOrders && <span className="sub-list-item-text">{`${numberOfBarrelOrders} Bottle${numberOfBarrelOrders > 1 ? 's' : ''} Available for pickup ${barrelReservePickupDate}`}</span>}
                  </Col>
                </>
              )}
          </Row>
          <h4 className="my-4">What's New</h4>
          <WhatsNew />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { member, rewards, staticContent, barrelOrders } = state;
  const orderData = barrelOrders?.data;
  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contactId = get(user, "id", "");
  const staticContentData = get(staticContent, "data", {});
  const barrelPromotionState = get(
    staticContentData,
    "barrelPromotionState",
    ""
  );
  const barrelReservePickupDate = get(
    staticContent.data,
    "barrelReservePickupDate",
    ""
  );

  return {
    member: member.data,
    isLoading: member.loading,
    rewards: rewards.data,
    contacts:  member.contacts,
    selectorValue: contactId,
    barrelPromotionState,
    barrelReservePickupDate,
    barrelOrders: get(orderData, "orderHistory", ""),
    barrelReserveOrder: get(orderData, "barrelReserveOrder", ""),
  };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
